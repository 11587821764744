import React from "react";
import theme from "#theme";
import { Toaster } from "react-hot-toast";
import { LoadingAnimate } from "#components";
import { useTranslation } from "react-i18next";
import { IconsProvider } from "@nubeteck/icons";
import { QueryClientProvider } from "@tanstack/react-query";
import { Provider as InversifyProvider } from "inversify-react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthContextProvider, PermissionsProvider } from "#contexts";
import {
  ThemeProvider,
  BreadcrumbActionsProvider,
  MaterialDesignIconsProvider,
} from "@nubeteck/components";

import queryClient from "./query.config";
import container from "./inversify.config";

import "./I18next.config";

const RouterLazy = React.lazy(
  () => import("./contexts/router-context/router-context"),
);

const Application = () => {
  const { t } = useTranslation("app");

  const renderLoading = React.useCallback((message: string) => {
    return (
      <LoadingAnimate
        message={message}
        imgSrc="/logo.webp"
        imgAlt="LoadingAuth"
      />
    );
  }, []);

  return (
    <InversifyProvider container={() => container}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider renderLoading={renderLoading}>
          <PermissionsProvider renderLoading={renderLoading}>
            <ThemeProvider theme={theme}>
              <BreadcrumbActionsProvider>
                <React.Suspense
                  fallback={renderLoading(t("loading_routes_message"))}
                >
                  <RouterLazy />
                </React.Suspense>
              </BreadcrumbActionsProvider>
              <Toaster />
              <IconsProvider />
              <MaterialDesignIconsProvider />
            </ThemeProvider>
          </PermissionsProvider>
        </AuthContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </InversifyProvider>
  );
};

export default Application;
