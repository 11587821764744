import { BillingModule } from "@nubeteck/billing_module";
import { ContactsModule } from "@nubeteck/contacts_module";
import { InventoryModule } from "@nubeteck/inventory_module";
import { ReportingModule } from "@nubeteck/reporting_module";
import { AccountingModule } from "@nubeteck/accounting_module";
import { CostCenterModule } from "@nubeteck/cost_center_module";
import { AccountsPayableModule } from "@nubeteck/accounts_payable_module";
import { ReconciliationModule } from "@nubeteck/uce_reconciliation_module";
import { AccountsReceivableModule } from "@nubeteck/accounts_receivable_module";

import i18n from "./I18next.config";
import container from "./inversify.container";

ContactsModule.register(container, i18n);
InventoryModule.register(container, i18n);
AccountingModule.register(container, i18n);
ReconciliationModule.register(container, i18n);
AccountsReceivableModule.register(container, i18n);
BillingModule.register(container, i18n);
AccountsPayableModule.register(container, i18n);
CostCenterModule.register(container, i18n);
ReportingModule.register(container, i18n);

export default container;
