import React from "react";
import { Icon } from "@nubeteck/icons";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Editor, useEditor, EditorContent } from "@tiptap/react";
import { Flex, Divider, Dropdown, IDropdownProps } from "@nubeteck/components";
import {
  IRules,
  FormItem,
  useWatch,
  useController,
  IFormItemProps,
  useFormContext,
} from "@nubeteck/formeck";

import { TooltipIcon } from "../tooltip-icon";

import { EditorCard } from "./editor-input.styled";

type ItemTypes = NonNullable<NonNullable<IDropdownProps["menu"]>["items"]>;

interface IMenuEditorProps {
  editor: Editor;
  variables?: ItemTypes;
}

const MenuEditor = React.memo(({ editor, variables }: IMenuEditorProps) => {
  const headingDropdownOptions = Array.from({ length: 7 }, (_, index) => {
    return {
      key: `${index}`,
      itemIcon:
        index === 0 ? <Icon name="heading" /> : <Icon name={`h-${index}`} />,
      onClick: () => {
        if (index === 0) {
          editor.chain().setParagraph().run();
        } else {
          editor
            .chain()
            .toggleHeading({ level: index as never })
            .run();
        }
      },
    };
  });

  return (
    <Flex
      style={{
        flex: 1,
        overflowX: "auto",
        justifyContent: "space-between",
      }}
    >
      <Flex style={{ gap: 10, alignItems: "center" }}>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: headingDropdownOptions,
            selectedKeys: [`${editor.getAttributes("heading").level ?? 0}`],
          }}
        >
          <Flex>
            <TooltipIcon
              title="Títulos"
              name={
                editor.getAttributes("heading").level
                  ? `h-${editor.getAttributes("heading").level}`
                  : "heading"
              }
            />
            <Icon name="selector" />
          </Flex>
        </Dropdown>
        <TooltipIcon
          name="bold"
          title="Negrita"
          colorPrimary={editor.isActive("bold")}
          onClick={() => {
            if (editor.can().chain().toggleBold().run()) {
              editor.chain().toggleBold().run();
            }
          }}
        />
        <TooltipIcon
          name="italic"
          title="Itálica"
          colorPrimary={editor.isActive("italic")}
          onClick={() => {
            if (editor.can().chain().toggleItalic().run()) {
              editor.chain().toggleItalic().run();
            }
          }}
        />
        <TooltipIcon
          name="underline"
          title="Subrayar"
          colorPrimary={editor.isActive("underline")}
          onClick={() => {
            if (editor.can().chain().toggleUnderline().run()) {
              editor.chain().toggleUnderline().run();
            }
          }}
        />
        <TooltipIcon
          title="Tachado"
          name="strikethrough"
          colorPrimary={editor.isActive("strike")}
          onClick={() => {
            if (editor.can().chain().toggleStrike().run()) {
              editor.chain().toggleStrike().run();
            }
          }}
        />
        <Divider type="vertical" style={{ margin: 0, height: "100%" }} />
        <TooltipIcon
          name="list"
          title="Lista"
          colorPrimary={editor.isActive("bulletList")}
          onClick={() => {
            editor.chain().toggleBulletList().run();
          }}
        />
        <TooltipIcon
          name="list-numbers"
          title="Lista ordenada"
          colorPrimary={editor.isActive("orderedList")}
          onClick={() => {
            editor.chain().toggleOrderedList().run();
          }}
        />
        <Divider type="vertical" style={{ margin: 0, height: "100%" }} />
        <TooltipIcon
          title="Cita"
          name="blockquote"
          colorPrimary={editor.isActive("blockquote")}
          onClick={() => {
            editor.chain().toggleBlockquote().run();
          }}
        />
        <TooltipIcon
          title="Divisor"
          name="separator-horizontal"
          onClick={() => {
            editor.chain().setHorizontalRule().run();
          }}
        />
        <TooltipIcon
          name="page-break"
          title="Romper (o Hard Break)"
          onClick={() => {
            editor.chain().setHardBreak().run();
          }}
        />
        <TooltipIcon
          name="code"
          title="Formato código"
          onClick={() => {
            editor.chain().toggleCode().run();
          }}
        />
        <Divider type="vertical" style={{ margin: 0, height: "100%" }} />
        {!!variables?.length && (
          <Dropdown
            trigger={["click"]}
            align={{ offset: [-40, 4] }}
            menu={{
              items: variables ?? [],
              onClick: (info) => {
                const match = info.key.match(/\((.*?)\)/);
                editor
                  .chain()
                  .insertContent(`{{${match ? match[1] : null}}}`)
                  .run();
              },
            }}
          >
            <TooltipIcon name="variable" title="Variables" />
          </Dropdown>
        )}
        <TooltipIcon
          name="clear-formatting"
          title="Limpiar formatos"
          onClick={() => {
            editor.chain().focus("all").clearNodes().run();
            editor.chain().focus("all").unsetAllMarks().run();
          }}
        />
        <TooltipIcon
          title="Deshacer"
          name="arrow-back-up"
          onClick={() => {
            editor.chain().undo().run();
          }}
        />
        <TooltipIcon
          title="Rehacer"
          name="arrow-forward-up"
          onClick={() => {
            editor.chain().redo().run();
          }}
        />
      </Flex>
      <Dropdown
        trigger={["click"]}
        align={{ offset: [-40, 4] }}
        menu={{
          items: [
            {
              key: "codeBlock",
              label: "Bloque de código",
              onClick: () => {
                editor.chain().toggleCodeBlock().run();
              },
            },
          ],
        }}
      >
        <TooltipIcon title="Mas opciones" name="dots-vertical" />
      </Dropdown>
    </Flex>
  );
});

MenuEditor.displayName = "MenuEditor";

const extensions = [
  Underline,
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

interface IEditorInputProps
  extends Partial<Omit<IFormItemProps, "rules" | "children">> {
  name: string;
  rules?: IRules;
  variables?: ItemTypes;
  addPlainText?: boolean;
}

const EditorInput = ({
  name,
  rules,
  variables,
  addPlainText,
  ...props
}: IEditorInputProps) => {
  const { setValue } = useFormContext();
  const { field } = useController({ name, rules });
  const value = useWatch({ name, exact: true });

  const editor = useEditor({
    extensions,
    autofocus: false,
    onUpdate: ({ editor }) => {
      field.onChange(editor.getHTML());
    },
  });

  React.useEffect(() => {
    if (value !== editor?.getHTML()) {
      editor?.commands.setContent(value);
    }

    if (addPlainText) {
      setValue(`${name}Plano`, editor?.getText() ?? "");
    }
  }, [addPlainText, editor, name, setValue, value]);

  if (!editor) return null;

  return (
    <FormItem name={name} required={!!rules?.required} {...props}>
      <EditorCard
        size="small"
        style={{ position: "relative" }}
        styles={{ body: { padding: 0 }, header: { padding: 10 } }}
        title={<MenuEditor editor={editor} variables={variables ?? []} />}
      >
        <EditorContent
          editor={editor}
          placeholder="Escribe un asombroso texto :)"
        />
        {/* {editorMode === "bubble" && (
          <BubbleMenu
            editor={editor}
            tippyOptions={{ delay: 100, maxWidth: "100%" }}
          >
            <Card
              size="small"
              bodyStyle={{ padding: 5 }}
              style={{ boxShadow: "#0000004f 1px 1px 5px 0px" }}
            >
              <MenuEditor editor={editor} />
            </Card>
            </BubbleMenu>
          )} */}
      </EditorCard>
    </FormItem>
  );
};

EditorInput.displayName = "EditorInput";

export default EditorInput;
