export const themeColors = {
  primary100: "#C6F3E1",
  primary200: "#8DE0BE",
  primary300: "#5FCFA2",
  primary400: "#46bb84",
  primary500: "#3DA86B",
  primary600: "#2C874F",
  primary700: "#1D6536",
  primary800: "#0F431D",
  primary900: "#002B09",
};

export type ThemeColors = keyof typeof themeColors;
